import React, { useContext, useRef, useState } from "react"
import { Card } from "../../../components/layout/card/Card"
import { Text } from "../../../components/text/Text"
import { useTranslation } from "react-i18next"
import { Text as InputText } from "../../../components/input/text/Text"
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import FormInput from "../../../components/input/FormInput"
import Location, { GeolocApiTye } from "../../../components/input/location/Location"
import { Icon } from "../../../components/text/icon/icon"
import { Button } from "../../../components/button/Button"
import DatePicker from "../../../components/input/datePicker/DatePicker"
import InputNumber from "../../../components/input/number/Number"
import Switch from "../../../components/input/switch/Switch"
import TimePicker from "../../../components/input/timepicker/TimePicker"
import moment from "moment"
import { authContext } from "../../../service/api/authContext"
import { genererCode } from "../../../helper/format"
import Modal from "../../../components/Modal/Modal"
import Loader from "../../../components/Modal/Loading"
import Divider from "../../../components/divider/Divier"

type Inputs = {
	code: string
	startDate?: Date
	startHour?: moment.Moment
	name: string
	company: string
	mail: string
	phone: string
	firstLocation: GeolocApiTye
	lastLocation: GeolocApiTye
	steps: GeolocApiTye[]
	merchandises: {
		label: string
		dimensions: {
			length?: string
			width?: string
			height?: string
		}
		quantity?: number
		weight?: number
		picture?: File[]
	}[]
	crane: boolean
	craneHeight: number
}

const HeavyCustom = () => {
	const { t } = useTranslation("heavyCustom")
	const { token } = useContext(authContext)
	const {
		register,
		setValue,
		handleSubmit,
		control,
		watch,
		formState: { errors }
	} = useForm<Inputs>({
		defaultValues: {
			merchandises: [ { label: "", dimensions: {}}]
		}
	})
	const { fields: stepFields, append: appendStep, remove: removeStep } = useFieldArray({
		control,
		name: "steps"
	})
	const { fields: merchFields, append: appendMerch, remove: removeMerch } = useFieldArray({
		control,
		name: "merchandises"
	})

	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [askFormValid, setaskFormValid] = useState(false)
	const [loading, setLoading] = useState(false)
	const [askCode, setAskCode] = useState("")
	const formRef = useRef<HTMLFormElement>(null)
	// eslint-disable-next-line no-console
	const onSubmit: SubmitHandler<Inputs> = (data) => {
		if(loading) {
			return
		}
		const fetchData = async() => {
			setLoading(true)
			const generatedCode = genererCode()
			setAskCode(generatedCode)
			try {
				const formData = new FormData()

				Object.entries(data.merchandises).forEach(([index, merchandise]) => {
					if(merchandise.picture && merchandise.picture.length > 0) {
						formData.append(`merchandise${index}`, merchandise.picture[0])
					}
				})
				data.code = generatedCode
				formData.append("data", JSON.stringify(data))
				const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/search/order/custom-made/request`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`
					},
					body: formData
				})

				setaskFormValid(false)
				if(response.ok) {
					const dataResponse = await response.json()
					if(dataResponse.success) {
						setaskFormValid(true)
					}
				}
			} catch (error) {
				console.error("Erreur lors de la récupération des données de l'API", error)
				setaskFormValid(false)
			}
			setModalIsOpen(true)
			setLoading(false)
		}
		fetchData()
	}

	// Vérifiez si toutes les steps sont valides
	const steps = watch("steps")
	const merchandises = watch("merchandises")
	const crane = watch("crane")

	const invalidSteps = () => {
		if(!steps || steps.length <= 0) {
			return false
		}
		return steps.every((step, index) => errors.steps && errors.steps[index])
	}

	const invalidMerchandises = () => {
		if(!merchandises || merchandises.length <= 0) {
			return false
		}
		return merchandises.every((step, index) => errors.merchandises && errors.merchandises[index])
	}

	const handleRemoveFile = (index: number) => {
		setValue(`merchandises.${index}.picture`, [])
	}

	return <div className="bg-third min-h-full">
		<Loader open={loading}/>
		<Modal showCloseIcon={false} open={modalIsOpen} classNames={{ modal: "radius-common" }}>
			<div className="w-full h-fit mt-3 p-2">
				<div className="flex flex-col w-full justify-center items-start">
					<div className="w-full flex flex-row">
						<Icon type={askFormValid ? "check" : "cross"} size="medium" color={askFormValid ? "valid" : "warning"} className="mr-5"/>
						<Text className="mb-5 self-center" size="large">{t(askFormValid ? "popup.valid.title" : "popup.error.title")}</Text>
					</div>
					<Text className="self-center mb-2">{t(askFormValid ? "popup.valid.line.1" : "popup.error.line.1")}</Text>
					<div className="self-center mb-2">
						<Text>{t(askFormValid ? "popup.valid.line.2" : "popup.error.line.2")}</Text>
						{askFormValid && <Text size="medium" className="underline bold">{askCode}</Text>}
					</div>
					<Text className="self-center mb-10">{t(askFormValid ? "popup.valid.line.3" : "popup.error.line.3")}</Text>
					<div className="flex justify-center w-4/6 self-center">
						{askFormValid && <Button btnType="button" type="primary" text={t("popup.valid.button")} to={process.env.REACT_APP_WORDPRESS_MAIN_DOMAIN}/>}
						{!askFormValid && <Button btnType="submit" type="primary" text={t("popup.error.button")} onClick={() => {
							setModalIsOpen(false)
							handleSubmit(onSubmit)()
						}}/>}
					</div>
				</div>
			</div>
		</Modal>
		<div className="flex justify-center">
			<div className="w-11/12 md:w-11/12 xl:w-3/6">
				<Card type="primary" className="bg-white mt-10 w-3/6">
					<div className="flex justify-center mt-2">
						<Text size="large">{t("title")}</Text>
					</div>
					<div className="p-2 pl-5">
						<form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
							<div className="flex flex-col">
								<Text size="big" className="pb-2">{t("mission.start.title")}</Text>
								<div className="flex flex-col md:flex-row">
									<FormInput className="md:w-3/6 lg:w-2/6" inputText={t("mission.start.date.title")} error={!!errors.startDate} errorText={t("mission.start.date.error")}>
										<Controller
											name="startDate"
											control={control}
											defaultValue={undefined}
											rules={{ required: true, minLength: 1 }}
											render={({ field: { onChange, value }, fieldState: { error }}) => <DatePicker
												type="simple"
												error={!!error}
												date={value}
												setDate={onChange}
												placeholder={t("mission.start.date.placeholder")}
											/>
											}
										/>
									</FormInput>
									<FormInput className="md:w-3/6 lg:ml-10 lg:w-2/6" inputText={t("mission.start.hour.title")} error={!!errors.startHour} errorText={t("mission.start.hour.error")}>
										<Controller
											name="startHour"
											control={control}
											defaultValue={undefined}
											rules={{ required: true, minLength: 1 }}
											render={({ field: { onChange, value }, fieldState: { error }}) => <TimePicker error={!!error} time={value} defaultHour={moment().hour(7)!.minute(0)} setTime={onChange} placeholder={t("mission.start.hour.placeholder")}/>
											}
										/>
									</FormInput>
								</div>
							</div>
							<Divider/>{/* steps */}
							<div className="flex flex-col mt-5">
								<Text size="big" className="pb-2">{t("mission.step.title")}</Text>
								{(invalidSteps() || errors.firstLocation || errors.lastLocation) &&
                    <Text className="text-c-warning pl-5">{t("mission.step.detail.error")}</Text>}
								<div className="overflow-auto">
									<div className="w-full bg-neutralLight p-5 radius-common">
										<table className="w-full text-left border-c-third">
											<thead className="bg-third">
												<tr>
													<th scope="col" className="px-2 py-2 text-white rounded-tl-xl w-2/6 min-w-60">
														<Text className="text-c-primary">{t("mission.step.detail.table.head.libel")}</Text>
													</th>
													<th scope="col" className="px-2 py-2 text-white">
														<Text className="text-c-primary">{t("mission.step.detail.table.head.address")}</Text>
													</th>
													<th scope="col" className="w-4 rounded-tr-xl">
													</th>
												</tr>
											</thead>
											<tbody className="bg-neutralLight">
												<tr>
													<th scope="col" className="pt-2 pl-1 pr-1">
														<div className="flex p-2 bg-white radius-common">
															<Text>{t("mission.step.detail.table.body.first.title")}</Text>
														</div>
													</th>
													<th scope="col" className="pt-2 pl-1 pr-1">
														<Controller
															name="firstLocation"
															control={control}
															defaultValue={undefined}
															rules={{ required: true }}
															render={({ field: { onChange }, fieldState: { error }}) => <div className={`flex bg-white radius-common pr-2 ${error ? "warningForm" : ""}`}>
																<Location
																	type="neutral"
																	input={{ className: "m-2 w-full" }}
																	placeholder={t("mission.step.detail.table.body.first.placeholder")}
																	setAddress={onChange}
																/>
															</div>
															}
														/>
													</th>
													<td scope="col" className="pt-2 pl-1 pr-1">
														<div style={{ minHeight: 34 }} className="flex justify-center items-center p-2 bg-white radius-common">
															<span className="w-6 min-w-6"></span>
														</div>
													</td>
												</tr>
												{stepFields.map((field, index) => <tr key={index}>
													<th scope="col" className="pt-2 pl-1 pr-1">
														<div className="flex p-2 bg-white radius-common">
															<Text>{t("mission.step.detail.table.body.generic.title", { step: index + 1 })}</Text>
														</div>
													</th>
													<th scope="col" className="pt-2 pl-1 pr-1">
														<Controller
															name={`steps.${index}.address`}
															control={control}
															defaultValue={undefined}
															rules={{ required: true }}
															render={({ field: { onChange }, fieldState: { error }}) => <div className={`flex bg-white radius-common pr-2 ${error ? "warningForm" : ""}`}>
																<Location
																	type="neutral"
																	input={{ className: "m-2 w-full" }}
																	placeholder={t("mission.step.detail.table.body.generic.placeholder")}
																	setAddress={onChange}
																/>
															</div>
															}
														/>
													</th>
													<td scope="col" className="pt-2 pl-1 pr-1">
														<div
															style={{ minHeight: 34 }} className="flex justify-center items-center p-2 bg-white radius-common"
														>
															<button type="button" onClick={() => removeStep(index)}>
																<Icon type="trash" size="small"/></button>
														</div>
													</td>
												</tr>)}
												<tr>
													<th scope="col" className="rounded-bl-xl pt-2 pl-1 pr-1">
														<div className="flex p-2 bg-white radius-common">
															<Text>{t("mission.step.detail.table.body.last.title")}</Text>
														</div>
													</th>
													<th scope="col" className="rounded-br-xl pt-2 pl-1 pr-1">
														<Controller
															name="lastLocation"
															control={control}
															defaultValue={undefined}
															rules={{ required: true }}
															render={({ field: { onChange }, fieldState: { error }}) => <div className={`flex bg-white radius-common pr-2 ${error ? "warningForm" : ""}`}>
																<Location
																	type="neutral"
																	input={{ className: "m-2 w-full" }}
																	placeholder={t("mission.step.detail.table.body.last.placeholder")}
																	setAddress={onChange}
																/>
															</div>
															}
														/>
													</th>
													<td scope="col" className="pt-2 pl-1 pr-1">
														<div style={{ minHeight: 34 }} className="flex justify-center items-center p-2 bg-white radius-common"
														>
															<span className="w-6 min-w-6"></span>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
										<div className="flex justify-center mt-5">
											<Button className="w-4/5" type="none" btnType="button" text={t("mission.step.detail.table.add")} onClick={() => appendStep({})}/>
										</div>
									</div>
								</div>
							</div>
							<Divider/> {/* Merch */}
							<div className="flex flex-col">
								<Text size="big" className="pb-2">{t("mission.merchandise.title")}</Text>
								{invalidMerchandises() &&
                    <Text className="text-c-warning pl-5">{t("mission.merchandise.detail.error")}</Text>}
								<div className="overflow-auto">
									<div className="w-fit 2xl:w-full bg-neutralLight p-5 radius-common">
										<table className="w-full text-left border-c-third">
											<thead className="bg-third">
												<tr>
													<th scope="col" className="px-2 py-2 rounded-tl-xl min-w-56">
														<Text className="text-c-primary">{t("mission.merchandise.detail.table.head.merchandise")}</Text>
													</th>
													<th scope="col" className="px-2 py-2">
														<Text className="text-c-primary">{t("mission.merchandise.detail.table.head.dimension")}</Text>
													</th>
													<th scope="col" className="px-2 py-2">
														<Text className="text-c-primary">{t("mission.merchandise.detail.table.head.quantity")}</Text>
													</th>
													<th scope="col" className="px-1 py-2 min-w-36">
														<Text className="text-c-primary">{t("mission.merchandise.detail.table.head.weight")}</Text>
													</th>
													<th scope="col" className="px-2 py-2">
														<Text className="text-c-primary">{t("mission.merchandise.detail.table.head.picture")}</Text>
													</th>
													<th scope="col" className="w-4 rounded-tr-xl">
													</th>
												</tr>
											</thead>
											<tbody className="bg-neutralLight">
												{merchFields.map((merch, index) => <tr key={index}>
													<td scope="col" className="pt-2 pl-1 pr-1">
														<div className={`flex p-2 bg-white radius-common ${errors.merchandises && errors.merchandises[index] && errors.merchandises[index]!.label ? "warningForm" : ""}`}>
															<input
																type="text"
																placeholder={t("mission.merchandise.detail.table.body.merchandise")}
																className="w-full bg-white focus:outline-none" {...register(`merchandises.${index}.label`, { required: true })}
															/>
														</div>
													</td>
													<td scope="col" className="pt-2 pl-1 pr-1">
														<div className="flex p-2 bg-white radius-common">
															<input
																className="w-fit focus:outline-none"
																type="number"
																style={{ width: 74 }}
																min={1}
																{...register(`merchandises.${index}.dimensions.length`)}
																placeholder={t("mission.merchandise.detail.table.body.dimension.length")}
															/>
															x
															<input
																className="pl-1 w-fit focus:outline-none"
																type="number"
																style={{ width: 74 }}
																min={1}
																{...register(`merchandises.${index}.dimensions.width`)}
																placeholder={t("mission.merchandise.detail.table.body.dimension.width")}
															/>
															x
															<input
																className="pl-1 w-fit focus:outline-none"
																type="number"
																style={{ width: 74 }}
																min={1}
																{...register(`merchandises.${index}.dimensions.height`)}
																placeholder={t("mission.merchandise.detail.table.body.dimension.height")}
															/>
														</div>
													</td>
													<td scope="col" className="pt-2 pl-1 pr-1">
														<div className="flex p-2 bg-white radius-common">
															<input
																className="pl-1 max-w-28 focus:outline-none"
																type="number"
																style={{ width: 74 }}
																min={1}
																{...register(`merchandises.${index}.quantity`)}
																placeholder={t("mission.merchandise.detail.table.body.quantity")}
															/>
														</div>
													</td>
													<td scope="col" className="pt-2 pl-1 pr-1">
														<div className="flex p-2 bg-white radius-common">
															<input
																className="pl-1 focus:outline-none"
																type="number"
																style={{ width: 74 }}
																min={1}
																{...register(`merchandises.${index}.weight`)}
																placeholder={t("mission.merchandise.detail.table.body.weight")}
															/>
														</div>
													</td>
													<td scope="col" className="pt-2 pl-1 pr-1">
														<div className="flex p-2 bg-white radius-common" style={{ minWidth: 160, maxWidth: 160 }}>
															<Controller
																name={`merchandises.${index}.picture`}
																control={control}
																render={({ field }) => (
																	<>
																		<input
																			type="file"
																			id={`file-${index}`}
																			hidden
																			accept="image/png, image/jpeg"
																			onChange={(e) => field.onChange(e.target.files)}
																		/>
																		<label htmlFor={`file-${index}`} className="flex text-c-neutral w-full">
																			{field.value && field.value[0] ? (
																				<div className="flex w-full space-around">
																					<span className="truncate" style={{ maxWidth: 100 }}>
																						{field.value[0].name}
																					</span>
																				</div>
																			) : (
																				<div className="flex w-full">
																					<Icon type="upload" size="xtr-small" />
																					<span className="flex justify-center items-center pl-1">
																						{t("mission.merchandise.detail.table.body.picture")}
																					</span>
																				</div>
																			)}
																		</label>
																	</>
																)}
															/>
															{merchandises[index] && merchandises[index].picture && merchandises[index]!.picture!.length > 0 && (
																<button type="button" onClick={() => handleRemoveFile(index)}>
																	<Icon type="cross" size="xtr-small"/>
																</button>
															)}
														</div>
													</td>
													<td scope="col" className="pt-2 pl-1 pr-1">
														<div className="flex p-2 bg-white radius-common" style={{ minHeight: 34 }}>
															{index > 0 && <button type="button" onClick={() => removeMerch(index)}>
																<Icon type="trash" size="small"/>
															</button>}
															{index <= 0 && <span className="w-6 min-w-6"></span>}
														</div>
													</td>
												</tr>)}
											</tbody>
										</table>
										<div className="flex justify-center mt-5">
											<Button className="w-4/5" type="none" btnType="button" text={t("mission.merchandise.detail.table.add")} onClick={() => appendMerch({ label: "", dimensions: {}})}/>
										</div>
									</div>
								</div>
							</div>
							<Divider/> {/* Option */}
							<div className="flex flex-col">
								<Text size="big" className="pb-2">{t("mission.option.title")}</Text>
								<div className="flex flex-col">
									<div className="flex flex-wrap">
										<div className="flex flex-col space-between">
											<Text size="base" className="text-c-neutral">{t("mission.option.crane.title")}</Text>
											<div>
												<div className="lg:w-40">
													<Switch
														buttonState={crane}
														setButtonState={(val) => setValue("crane", val)}
														leftText={t("mission.option.crane.yes")}
														rightText={t("mission.option.crane.no")}
													/>
												</div>
											</div>
										</div>
										{crane && <div className="flex flex-col space-between">
											<div className="flex flex-col justify-center ml-6">
												<Text size="base" className="text-c-neutral">{t("mission.option.crane.hight")}</Text>
												<div className="w-24 ml-2">
													<InputNumber range={{ min: 0 }} register={register("craneHeight", { min: 0, value: 0 })}/>
												</div>
											</div>
										</div>}
									</div>
								</div>
							</div>
							<Divider/>
							<div className="flex flex-col mt-2">
								<Text size="big" className="pb-2">{t("mission.contact.title")}</Text>
								<div className="flex flex-col md:flex-row space-between">
									<FormInput inputText={t("mission.contact.company.title")} error={!!errors.company} className="md:w-5/6 md:pr-5 lg:pr-5" errorText={t("mission.contact.company.error")}>
										<InputText register={register("company", { required: true })} error={!!errors.company} placeholder={t("mission.contact.company.placeholder")}/>
									</FormInput>
									<FormInput inputText={t("mission.contact.name.title")} error={!!errors.name} className="md:w-5/6 md:pr-5 lg:pr-5" errorText={t("mission.contact.name.error")}>
										<InputText
											register={register("name", { required: true })}
											error={!!errors.name}
											placeholder={t("mission.contact.name.placeholder")}
										/>
									</FormInput>
									<FormInput inputText={t("mission.contact.mail.title")} error={!!errors.mail} className="md:w-5/6 md:pr-5 lg:pr-5" errorText={t("mission.contact.mail.error")}>
										<InputText
											placeholder={t("mission.contact.mail.placeholder")}
											/* eslint-disable-next-line require-unicode-regexp,prefer-named-capture-group */
											register={register("mail", { required: true, pattern: /^[\w-.]+(\+[^@]*)?@([\w-]+\.)+[\w-]{2,4}$/i })}
											error={!!errors.mail}
										/>
									</FormInput>
									<FormInput inputText={t("mission.contact.phone.title")} error={!!errors.phone} className="md:w-5/6 md:pr-5 lg:pr-5" errorText={t("mission.contact.phone.error")}>
										<InputText
											placeholder={t("mission.contact.phone.placeholder")}
											/* eslint-disable-next-line prefer-named-capture-group,require-unicode-regexp */
											register={register("phone", { required: true, pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/i })}
											error={!!errors.phone}
										/>
									</FormInput>
								</div>
							</div>
							<Divider/>
							<div className="flex justify-center mt-5">
								<div className="w-2/6">
									<Button btnType="submit" text={t("mission.send")} type="primary" icon={{ type: "signature" }} />
								</div>
							</div>
						</form>
					</div>
				</Card>
			</div>
		</div>
	</div>
}

export default HeavyCustom
