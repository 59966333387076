import React, { useContext, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import FormInput from "../input/FormInput"
import { Text as InputText } from "../input/text/Text"
import { Text } from "../text/Text"
import { useTranslation } from "react-i18next"
import { Button } from "../button/Button"
import Loader from "../Modal/Loading"
import { authContext } from "../../service/api/authContext"
import { checkAuthentication } from "../../service/api/api"

type FormValues = {
	username: string
	password: string
}

type LoginFormProps = {
	onSuccess?: () => void
}
const LoginForm = ({ onSuccess }: LoginFormProps) => {
	const { register, handleSubmit, formState: { errors }} = useForm<FormValues>()
	const [ loader, setLoader ] = useState<boolean>(false)
	const [ loginError, setLoginError ] = useState<boolean>(false)
	const { t } = useTranslation("common")
	const { login, logout, grantAuth } = useContext(authContext)
	const onSubmit: SubmitHandler<FormValues> = (data) => {
		if(loader) {
			return
		}
		const fetchData = async() => {
			setLoader(true)
			setLoginError(false)
			try {
				const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/api/login_check`, {
					headers: {
						"Content-Type": "application/json"
					},
					method: "POST",
					body: JSON.stringify(data)
				})
				if(response.ok) {
					const res = await response.json()
					const auth = await checkAuthentication(res.token)
					if(auth.isAuthenticated) {
						login(res.token, auth.user)
						grantAuth(auth.isAdmin, auth.isBillingMonthly)
					} else {
						logout()
					}
					if(onSuccess) {
						onSuccess()
					}
				} else {
					setLoginError(true)
				}
			} catch (error) {
				console.log("Erreur lors de la récupération des données de l'API", error)
				// todo gestion de l'erreur
			}
			setLoader(false)
		}
		fetchData()
		// Vous pouvez ajouter ici la logique pour envoyer les données au serveur
	}

	return <div className="flex flex-col items-center w-full">
		<Text size="large">{t("connection.title")}</Text>
		<Loader open={loader}/>
		<form className="w-full" onSubmit={handleSubmit(onSubmit)}>
			<div className="flex flex-col items-center w-full">
				<div className="flex w-full space-around mb-5">
					<FormInput inputText={t("connection.username")} error={!!errors.username} className="w-1/3" errorText={t("connection.username", { context: "error" })}>
						<InputText register={register("username", { required: true })} error={!!errors.username} placeholder={t("connection.username", { context: "placeholder" })}/>
					</FormInput>
					<FormInput inputText={t("connection.password")} error={!!errors.password} className="w-1/3" errorText={t("connection.password", { context: "error" })}>
						<InputText register={register("password", { required: true })} error={!!errors.password} placeholder={t("connection.password", { context: "placeholder" })} type="password"/>
					</FormInput>
				</div>
				<div className="flex flex-col w-full items-center space-around">
					<div className="flex flex-col w-1/3 pb-5">
						{loginError && <Text className="text-c-warning self-center pl-1">{t("connection.confirm", { context: "error" })}</Text>}
						<Button className="self-center" type="primary" text={t("connection.confirm")}/>
					</div>
					<div className="flex flex-col w-1/3">
						<Button className="self-center underline" type="none" text={t("connection.inscription")} showIcon={false} to={`${process.env.REACT_APP_TRUST_DOMAIN}/auth/login`}/>`
					</div>
				</div>
			</div>
		</form>
	</div>
}

export default LoginForm
